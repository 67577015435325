<template>
  <div class="logistics">
    <NavBar></NavBar>
    <van-steps direction="vertical" :active="0">
    <van-step>
      <h4>【城市】物流状态1</h4>
      <p>2016-07-12 12:40</p>
    </van-step>
    <van-step>
      <h4>【城市】物流状态2</h4>
      <p>2016-07-11 10:00</p>
    </van-step>
    <van-step>
      <h4>快件已发货</h4>
      <p>2016-07-10 09:30</p>
    </van-step>
  </van-steps>
  </div>
</template>

<script>
import NavBar from 'components/navBar.vue'
export default {
  name: 'logistics',
  data () {
    return {
      
    }
  },
  components: {
    NavBar
  }
}
</script>

<style lang="stylus" scoped>
  >>> .van-steps
    padding-left 15%
    margin-top 10px
</style>