<template>
  <div class="navBar">
    <van-nav-bar
      :title="this.$route.meta.title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
  </div>
</template>

<script>
export default {
  name: 'navBar',
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style>

</style>